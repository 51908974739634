<main class="p2-app">
    <section class="events">
        <div class="content">
            <h2>
                <div class="cursive">check out our</div>
                <div>Free Events</div> 
            </h2>

            <h5 *ngIf="!!id && !showAllEvents"><a routerLink="/events">&lt; See all events</a></h5>

            <event-calendar [id]="id" [showAllEvents]="showAllEvents" [register]="register"></event-calendar>
        </div>
    </section>
</main>
