import { AfterViewInit, Component, ElementRef, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-events',
	templateUrl: './events.component.html',
	styleUrls: ['./events.component.scss']
})
export class EventsComponent  {
	id: string;
	showAllEvents: boolean = false;
	register: boolean = false;

	constructor(private route: ActivatedRoute) {

	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
            if (!!params.get('id')) {
                if (this.id != params.get('id')) {
                    this.id = params.get('id');
                }
            }
        });

        this.route.queryParamMap.subscribe(params => {
            if (!!params.get('showAllEvents')) {
                this.showAllEvents = params.get('showAllEvents') == 'true';
            }
            if (!!params.get('register')) {
                this.register = params.get('register') == 'true';
            }
        });
    }
}
