import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { forkJoin, map, Observable, Subscription, switchMap, take } from 'rxjs';
import { Contact } from 'src/app/_models/contact';
import { ContactService } from 'src/app/_services/contact.service';
import { UserService } from 'src/app/_services/user.service';
import { JWTTokenService } from '../_services/jwt-token.service';
import { ClientInfo } from '../_models/client-info';
import { Ethnicity } from '../_models/ethnicity';
import { Event } from '../_models/event';
import { EventService } from '../_services/event.service';
import { ContactEvent } from '../_models/contact-event';
import * as dayjs from 'dayjs';

@Component({
    templateUrl: './checkin.component.html',
    styleUrls: ['./checkin.component.scss']
})
export class CheckinComponent implements OnInit {
    token: string;

    event: Event;
    contact: Contact;
    
    contactEvent: ContactEvent = {} as ContactEvent;

    isLoading: boolean = false;
    basicInfoComplete: boolean = false;
    childSelectionComplete: boolean = false;
    currentStep: string;
    isAlreadyRegistered: boolean = false;
    isProfileFormValid: boolean = true;
    isClientFormValid: boolean = true;
    isTokenValid: boolean = true;
    userSubscription: Subscription;

    constructor(private eventService: EventService, private contactService: ContactService, private route: ActivatedRoute, private userService: UserService) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (!!params.get('id')) {
                if (this.token != params.get('id')) {
                    this.token = params.get('id');

                    let tokenStr = atob(this.token);//`${this.event.guid}:${new Date().getTime()}`);

                    if (tokenStr.split(':').length != 2) {
                        this.isTokenValid = false;
                        return;
                    }

                    let eventGuid = tokenStr.split(':')[0];

                    this.eventService.getEvent(eventGuid).pipe(take(1)).subscribe(event => {
                        this.event = event;

                        this.userSubscription = this.userService.getUser().subscribe(user => {
                            if (!!user) {
                                this.contact = user;

                                if (this.isClient()) {
                                    this.contact.clientInfo = this.contact?.clientInfo ?? { preferredLanguage: 'English' } as ClientInfo;
                                }
                            }
                            else {
                                this.contact = null;
                            }
                    
                            this.contactEvent = {
                                event: this.event,
                                eventGuid: this.event?.guid,
                                contact: this.contact,
                                contactGuid: this.contact?.guid
                            } as ContactEvent;
                    
                            if (!!this.event && !!this.contact) {
                                this.currentStep = 'contact-basic-info';
                    
                                if (this.isAllPersonalInfoComplete() || !this.isClient()) {
                                    this.currentStep = 'child-selection';
                                }
                            }
                            else {
                                this.currentStep = 'contact-selection';
                            }
                    
                            if (!!this.event && !!this.contact) {
                                this.eventService.getContactEvent(this.event.guid, this.contact.guid).subscribe(res => {
                                    if (!!res) {
                                        this.contactEvent = res;
                    
                                        // Copy these so we don't lose the child.isSelected
                                        this.contactEvent.contact = _.cloneDeep(this.contact);

                                        this.contactEvent.event = _.cloneDeep(this.event);
                                        
                                        this.isAlreadyRegistered = true;
                                    }
                    
                                    this.updateChildSelectionStatus();
                                })
                            }
                        })
                    });
                }
            }
        })
    }

    ngOnDestroy() {
        if (!!this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    isClient() {
        return !this.contact?.roles || this.contact.roles.length == 0 || _.some(this.contact.roles, r => r.name == 'Client');
    }

    updateChildSelectionStatus() {
        if (this.contactEvent.contact?.children?.length > 0 && !this.event.isParentsOnly && this.isClient()) {
            if (this.isAlreadyRegistered) {
                _.each(this.contactEvent.contact.children, child => {
                    this.eventService.getChildContactEvent(this.event.guid, this.contactEvent.contact.guid, child.guid).pipe(take(1)).subscribe(res => {
                        child.isSelected = !!res;
                    });
                });
            }
            else {
                _.each(this.contactEvent.contact.children, child => { child.isSelected = true; });
            }
        } 
    }

    onEditContact() {
        this.currentStep = 'contact-basic-info';
    }

    isNewContact() {
        return !this.contactEvent.contact?.guid || this.contactEvent.contact?.guid.length == 0;
    }

    onNextClick() {
        if (this.currentStep == 'contact-selection') {
            this.userService.setUser(this.contactEvent.contact);

            if (this.isAllPersonalInfoComplete() || !this.isClient()) {
                this.currentStep = 'child-selection';
            }
            else {
                if (!this.contactEvent.contact.clientInfo) {
                    this.contactEvent.contact.clientInfo = {} as ClientInfo;
                }

                this.currentStep = 'contact-basic-info';
            }
        }
        else if (this.currentStep == 'contact-basic-info') {
            let contact = _.cloneDeep(this.contactEvent.contact);

            let apiTask = this.isNewContact() ? this.contactService.addContact(this.contactEvent.contact) : this.contactService.updateContact(this.contactEvent.contact);

            apiTask.pipe(take(1)).subscribe(res => {
                this.contactEvent.contact = res;

                this.userService.setUser(this.contactEvent.contact);

                if (this.isClient()) {
                    this.contactEvent.contact.clientInfo = contact.clientInfo;
                    this.contactEvent.contact.clientInfo.contactGuid = this.contactEvent.contact.guid;
                    this.contactEvent.contact.ethnicities = contact.ethnicities;
    
                    forkJoin([
                        this.contactService.updateClientInfo(this.contactEvent.contact.clientInfo),
                        this.contactService.updateContactEthnicities(this.contact.guid, _.map(this.contactEvent.contact.ethnicities, e => e.guid))
                    ]).pipe(take(1)).subscribe(res => {  
                        this.currentStep = 'child-selection';
                        this.updateChildSelectionStatus();
                    });
                }
                else {
                    this.currentStep = 'child-selection';
                }
            })
        }
        else if (this.currentStep == 'child-selection') {
            this.saveData().pipe(take(1)).subscribe({ 
                next: res => {
                    this.currentStep = 'confirmation';
                },
                error: err => {
                    this.isLoading = false;
                }
            })
        }
    }

    isAllPersonalInfoComplete() {
        return !!this.contactEvent &&
            this.contactEvent.contact?.firstName?.length > 0 &&
            this.contactEvent.contact?.lastName?.length > 0 &&
            this.contactEvent.contact?.phoneNumber?.length > 0 &&
            this.contactEvent.contact?.email?.length > 0 &&
            dayjs(this.contactEvent.contact?.dateOfBirth).isValid() &&
            this.contactEvent.contact?.clientInfo?.city?.length > 0 &&
            this.contactEvent.contact?.clientInfo?.source?.length > 0;
    }

    onChangeContact() {
        this.contactEvent.contact = null;

        this.currentStep = 'contact-selection';

        localStorage.removeItem('p2-token');
    }

    saveData(): Observable<ContactEvent[]> {
        this.isLoading = true;

        // Backfill
        this.contactEvent.contactGuid = this.contactEvent.contact.guid;
        this.contactEvent.eventGuid = this.contactEvent.event.guid;

        let tasks: Observable<ContactEvent>[] = [];

        tasks.push(this.processContactEvent());

        // Add contact event record for each child
        if (this.contactEvent.contact?.children?.length > 0 && !this.event.isParentsOnly && this.isClient()) {
            _.each(this.contactEvent.contact.children, child => {
                tasks.push(this.processChildContactEvent(child));
            })
        }

        return forkJoin(tasks).pipe(take(1), map(res => {
            this.isLoading = false;

            return res;
        }));
    }

    processContactEvent(): Observable<ContactEvent> {
        return this.eventService.registerForEvent(this.contactEvent.event.guid, this.contactEvent.contact.guid).pipe(take(1), switchMap(res => {
            return this.eventService.checkInToEvent(this.event.guid, this.contact.guid).pipe(take(1), map(res => {

                res.contact = _.cloneDeep(this.contact);
                res.event = _.cloneDeep(this.event);
                
                this.contactEvent = res;
                
                return this.contactEvent;
            }))
        }))
    }

    processChildContactEvent(child: Contact): Observable<ContactEvent> {
        let parentId = this.contactEvent.contact.guid;

        if (child.isSelected) {
            return this.eventService.registerChildForEvent(this.event.guid, parentId, child.guid).pipe(take(1), switchMap(res => {
                return this.eventService.checkInChildToEvent(this.event.guid, parentId, child.guid).pipe(take(1), map(res => {
                    return res;
                }))
            }))
        }
        else {
            return this.eventService.cancelChildEventRegistration(this.event.guid, parentId, child.guid);
        }
    }
}
