import { Component, OnInit, Input, EventEmitter, Output, Inject } from '@angular/core';
import * as _ from 'lodash';

export interface CheckboxListOption {
    name: string;
    value: any;
}

@Component({
    selector: 'checkbox-list',
    templateUrl: './checkbox-list.component.html',
    styleUrls: ['./checkbox-list.scss']
})
export class CheckboxListComponent implements OnInit {
    @Input() selectedOptions: CheckboxListOption[] = [];
    @Input() allOptions: CheckboxListOption[] = [];

    @Output() selectedOptionsChange = new EventEmitter<CheckboxListOption[]>();

    isLoading: boolean = false;
    
    constructor() {

    }

    ngOnInit() {
    }

    isSelected(option: CheckboxListOption) {
        return !!this.selectedOptions.find(x => x.value.guid === option.value.guid);
    }

    onClick(option: CheckboxListOption) {
        if (this.isSelected(option)) {
            this.selectedOptions = this.selectedOptions.filter(x => x.value.guid !== option.value.guid);
        } else {
            this.selectedOptions.push(option);
        }

        this.selectedOptionsChange.emit(this.selectedOptions);
    }
}
