import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';
import { Dialog } from '@angular/cdk/dialog';
import { ClientInfo } from 'src/app/_models/client-info';

@Component({
    selector: 'photo-release-form',
    templateUrl: './photo-release-form.component.html',
    styleUrls: ['./photo-release-form.scss']
})
export class PhotoReleaseFormComponent implements OnInit {
    @ViewChild('myForm', { static: true }) myForm: NgForm;

    @Input() clientInfo: ClientInfo;
    
    @Output() clientInfoChange = new EventEmitter<ClientInfo>();

    photoReleaseResponse?: string = null;
    originalPhotoReleaseResponse?: string = null;

    allOptions: any[] = [
        { name: 'Accept', value: 'Accepted' },
        { name: 'Decline', value: 'Declined' }
    ];
    
    constructor(public dialog: Dialog) {
        
    }

    ngOnInit() {
        this.photoReleaseResponse = this.getWaiverStatus();
        this.originalPhotoReleaseResponse = this.photoReleaseResponse;

        if (this.photoReleaseResponse == 'Unknown') {
            this.photoReleaseResponse = 'Accepted';

            this.onChange(this.photoReleaseResponse);
        }
    }

    onChange(status: string) {
        if (status == 'Accepted') {
            this.clientInfo.dateWaiverAccepted = new Date();
        }
        else {
            this.clientInfo.dateWaiverDeclined = new Date();
        }

        this.clientInfoChange.emit(this.clientInfo);
    }

    onUnlockChange() {
        this.originalPhotoReleaseResponse = null;
    }
    
    getWaiverStatus(): string {
        if (!!this.clientInfo.dateWaiverAccepted && !!this.clientInfo.dateWaiverDeclined) {
            if (this.clientInfo.dateWaiverAccepted > this.clientInfo.dateWaiverDeclined) {
                return 'Accepted';
            }
            else {
                return 'Declined';
            }
        }
        else if (!!this.clientInfo.dateWaiverAccepted) {
            return 'Accepted';
        }
        else if (!!this.clientInfo.dateWaiverDeclined) {
            return 'Declined';
        }
        else {
            return 'Unknown';
        }
    }
}
