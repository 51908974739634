import { Component } from '@angular/core';
import { expandCollapse } from './_components/animations/animations'
import { Angulartics2, Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Contact } from './_models/contact';
import { UserService } from './_services/user.service';

declare const snaptr; 

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [expandCollapse]
})
export class AppComponent {
    title = 'parenting-with-purpose';
    isAboutClicked: boolean = false;
    isMenuOpen: boolean = false;
    isProfileMenuOpen: boolean = false;

    userSubscription: Subscription;
    currentUser: Contact;

    constructor(private userService: UserService, private angulartics: Angulartics2GoogleGlobalSiteTag, private angulartics2: Angulartics2, private router: Router) {
        
        this.userSubscription = this.userService.getUser().subscribe(user => {
            this.currentUser = user;
        });

        this.userService.init();
        
        angulartics.startTracking();

        angulartics2.eventTrack.next({
            action: 'conversion',
            properties: {
                gstCustom: {
                    send_to: 'AW-10831837062/ErySCLXJ0owDEIbvgq0o',
                    transaction_id: ''
                }
            }
        });
    }

    ngOnInit() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            snaptr('track', 'PAGE_VIEW', {description: event.url });
        });
    }
}
