import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { JWTTokenService } from './jwt-token.service';
import { Ethnicity } from '../_models/ethnicity';

@Injectable()
export class EthnicityService {
    api: string = environment.api;

    constructor(private httpClient: HttpClient, private jwtTokenService: JWTTokenService) { }

    getAll(): Observable<Ethnicity[]> {
        var url = `${this.api}ethnicities`;

        return this.httpClient.get<Ethnicity[]>(url);
    }
}
