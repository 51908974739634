<main>
    <h2>About</h2>
    <p>Parenting with Purpose (P2) is a Christ-centered 501(c)(3) organization formed in 2020 with a mission to equip expectant and parenting teens to make healthy decisions about parenting, relationships, and their future.  P2 is not a program or class with an end date -- it is relational ministry, on a daily basis in some cases.</p>
    <p>For pregnant teens considering abortion due to concerns about their ability to parent and provide, Parenting with Purpose offers the assurance of one-on-one mentoring and coaching that doesn't end when the baby is delivered.  The mom mentor will use her experience and Spirit-led wisdom to lovingly walk alongside the new mother as she stumbles through the trials of parenting.</p>
    <p>Our work is the natural next step in the pro-life movement, caring for a mother AFTER she has chosen life.</p>

    <section>
        <h3>Troubling Statistics</h3>

        <ul>
            <li>20% of teen moms have repeat teen pregnancies. (HHS.gov 2018)</li>
            <li>Only 40% of teen moms will graduate from high school. (NCSL 2018)</li>
            <li>Only 2% of teen moms go to college. (NCSL 2018)</li>
            <li>Only 32% of teen moms get off public aid and become financially independent. (NCSL 2018)</li>
            <li>Only 34% of teen moms break the cycle of generational teen parenting, poverty, and poor outcomes for their children. (NCSL 2018)</li>
        </ul>
    </section>

    <section>
        <h3>Core Objectives</h3>

        <p>We seek to:</p>
        <ul>
            <li>Introduce individuals to the God who loves them unconditionally.  Or for believers, help them take the next step in their journey with Christ.</li>
            <li>Build a parent's identity in Jesus Christ -- not in worldly perception, relationships, or status.</li>
            <li>Guide single parents toward God-honoring relationship choices that will benefit them and their child.</li>
            <li>Keep both parents involved in the child's life (when the situation is healthy).</li>
            <li>Steer parents toward a completed education and/or job training to provide a steady income for their family.</li>
            <li>Equip parents with life skills that will help them care for their child and stand on their own two feet.</li>
            <li>Direct parents to a Bible-teaching church that will love and shepherd their families.</li>
            <li>Partner with other community organizations to provide the help struggling families need.</li>
        </ul>
    </section>

    <section>
        <h3>Statement of Faith</h3>
        <p>As adopted by the <a href="//nae.net" target="_blank">National Association of Evangelicals</a></p>
        <ul>
            <li>We believe the Bible to be the inspired, the only infallible, authoritative Word of God.</li>
            <li>We believe that there is one God, eternally existent in three persons: Father, Son and Holy Spirit.</li>
            <li>We believe in the deity of our Lord Jesus Christ, in His virgin birth, in His sinless life, in His miracles, in His vicarious and atoning death through His shed blood, in His bodily resurrection, in His ascension to the right hand of the Father, and in His personal return in power and glory.</li>
            <li>We believe that for the salvation of lost and sinful people, regeneration by the Holy Spirit is absolutely essential.</li>
            <li>We believe in the present ministry of the Holy Spirit by whose indwelling the Christian is enabled to live a godly life.</li>
            <li>We believe in the resurrection of both the saved and the lost; they that are saved unto the resurrection of life and they that are lost unto the resurrection of damnation.</li>
            <li>We believe in the spiritual unity of believers in our Lord Jesus Christ.</li>
        </ul>
    </section>
</main>
