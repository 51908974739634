import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { forkJoin, Subscription, take } from 'rxjs';
import { Contact } from 'src/app/_models/contact';
import { ContactService } from 'src/app/_services/contact.service';
import { UserService } from 'src/app/_services/user.service';
import { JWTTokenService } from '../_services/jwt-token.service';
import { ClientInfo } from '../_models/client-info';
import { Ethnicity } from '../_models/ethnicity';

@Component({
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    contact: Contact;
    clientInfo: ClientInfo = { preferredLanguage: 'English' } as ClientInfo;
    ethnicities: Ethnicity[] = [];

    isLoading: boolean = false;
    basicInfoComplete: boolean = false;
    childSelectionComplete: boolean = false;
    currentStep: string;
    isAlreadyRegistered: boolean = false;
    isProfileFormValid: boolean = true;
    isClientFormValid: boolean = true;

    constructor(private contactService: ContactService, private userService: UserService, private router: Router, private jwtTokenService: JWTTokenService) {
    }

    ngOnInit() {
        let phone = this.jwtTokenService.getPhoneNumber();
        let contactId = this.jwtTokenService.getContactGuid();

        if (!!phone && !contactId) {
            this.contact = { phoneNumber: phone } as Contact;
            this.clientInfo = { preferredLanguage: 'English' } as ClientInfo;
        
            this.currentStep = 'contact-basic-info';
        }
        else {
            this.currentStep = 'contact-selection';
        }
    }

    onCancel() {

    }

    isNewContact() {
        return !this.contact?.guid || this.contact?.guid.length == 0;
    }

    onContactChanged(contact: Contact) {
        if (!!contact?.guid) {
            this.userService.setUser(contact);

            // If user is already registered, they can't register again. navigate to events page
            if (this.contactService.isBasicInfoComplete(contact)) {
                this.router.navigate(['/user/events']);
            }
            else {
                // The user has logged in with a phone number to an imported account, but has not completed their profile
                this.router.navigate(['/user/profile']);
            }
        }
        else if (!!contact?.phoneNumber) {
            this.userService.setUser(null);
    
            this.onNextClick();
        }
    }

    onNextClick() {
        if (this.currentStep == 'contact-selection') {
            this.currentStep = 'contact-basic-info';
        }
        else if (this.currentStep == 'contact-basic-info') {
            this.contactService.addContact(this.contact).pipe(take(1)).subscribe(res => {
                this.contact = res;
                this.userService.setUser(this.contact);
    
                this.clientInfo.contactGuid = this.contact.guid;

                forkJoin([
                    this.contactService.updateClientInfo(this.clientInfo),
                    this.contactService.updateContactEthnicities(this.contact.guid, _.map(this.ethnicities, e => e.guid))
                ]).pipe(take(1)).subscribe(res => {  
                    this.currentStep = 'child-selection';
                });
            })
        }
        else if (this.currentStep == 'child-selection') {
            this.router.navigate(['/user/events']);
        }
    }

}
