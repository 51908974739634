<header>
    <div class="blockout" *ngIf="isMenuOpen || isProfileMenuOpen" (click)="isMenuOpen = false; isProfileMenuOpen = false;">

    </div>
    <section class="info">
        <div class="content">
            <span><a routerLink="/home" fragment="who-we-serve"><img src="../../assets/location-icon.png" />Serving Floyd, Clark, and Harrison counties</a></span>
        </div>
    </section>
    <section>
        <div class="content">
            <a class="logo" routerLink="/home">
                <img src="../../assets/logo.png" />
            </a>

            <div class="mobile">
                <div class="flex-row tight">
                    <user-profile-menu [contact]="currentUser" [(isProfileMenuOpen)]="isProfileMenuOpen"></user-profile-menu>
                    <a class="hamburger" (click)="isMenuOpen = !isMenuOpen"><img src="../../assets/hamburger-menu.png"/></a>
                </div>
                <nav class="menu" *ngIf="isMenuOpen" [@expandCollapse]>
                    <ul (mouseup)="isMenuOpen = false">
                        <li><a routerLink="/events">Events</a></li>
                        <li><a routerLink="/life-skills-classes">Classes</a></li>
                        <li><a routerLink="/volunteer">Volunteer</a></li>
                        <!-- <li><a href="home">Events</a></li> -->
                        <!-- <li><a routerLink="/jobs">Jobs</a></li> -->
                        <li><a routerLink="/about">About</a></li>
                        <!-- <li><a (click)="isAboutClicked = !isAboutClicked">About</a>
                            <ul class="subnav" *ngIf="isAboutClicked" [@expandCollapse]>
                                <li><a href="mission">Our Mission</a></li>
                                <li><a href="partners">Partners</a></li>
                                <li><a href="staff-board">Staff & Board</a></li>
                            </ul>
                        </li> -->
                        <li><a routerLink="/register" *ngIf="!currentUser" class="button"><button class="light-blue">Register</button></a></li>
                        <li><a routerLink="/donate" class="button"><button class="pink">Donate</button></a></li>
                    </ul>
                </nav>
            </div>

            <nav class="menu middle full">
                <ul>
                    <li><a routerLink="/events">Events</a></li>
                    <li><a routerLink="/life-skills-classes">Classes</a></li>
                    <li><a routerLink="/volunteer">Volunteer</a></li>
                    <!-- <li><a href="home">Events</a></li> -->
                    <!-- <li><a routerLink="/jobs">Jobs</a></li> -->
                    <li><a routerLink="/about">About</a></li>
                    <!-- <li><a (click)="isAboutClicked = !isAboutClicked">About</a>
                        <ul class="subnav" *ngIf="isAboutClicked">
                            <li><a href="mission">Our Mission</a></li>
                            <li><a href="partners">Partners</a></li>
                            <li><a href="staff-board">Staff & Board</a></li>
                        </ul>
                    </li> -->
                </ul>
            </nav>
            <nav class="menu full">
                <ul>
                    <li><button routerLink="/donate" class="pink">Donate</button></li>
                    <li><button routerLink="/register" *ngIf="!currentUser" class="light-blue">Register</button></li>
                    <li>
                        <user-profile-menu [contact]="currentUser" [(isProfileMenuOpen)]="isProfileMenuOpen"></user-profile-menu>
                    </li>
                </ul>
            </nav>
        </div>
    </section>
</header>

<router-outlet></router-outlet>

<footer>
    <section>
        <div class="content">
            <div class="container">
                <div class="navigation">
                    <h5>Navigation</h5>
                    <div class="navigation-container">
                        <ul>
                            <li><a routerLink="/events">Events</a></li>
                            <li><a routerLink="/life-skills-classes">Classes</a></li>
                            <li><a routerLink="/home" fragment="volunteer">Volunteer</a></li>
                        </ul>
                        <ul>
                            <!-- <li><a href="home">Events</a></li> -->
                            <li><a routerLink="/donate">Donate</a></li>
                            <!-- <li><a routerLink="/jobs">Jobs</a></li> -->
                            <li><a routerLink="/about">About</a></li>
                            <li><a routerLink="/register">Register</a></li>
                            <li><a href="../assets/privacy.htm">Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
    
                <div class="center">
                    <a href="home"><img src="../../assets/logo.png" class="logo" /></a>
    
                    <ul class="icons">
                        <li><a href="//facebook.com/P2ParentingWithPurpose" target="_blank"><img src="../../assets/facebook.png" /></a></li>
                        <li><a href="//instagram.com/P2ParentingWithPurpose" target="_blank"><img src="../../assets/instagram.png" /></a></li>
                    </ul>
                </div>
    
                <div class="contact">
                    <h5>Contact</h5>
                    <a href="mailto:info@equippedparents.org">info@equippedparents.org</a>
                </div>
            </div>

            <div class="copyright">
                <ul>
                    <li>&copy; 2024 Parenting with Purpose, Inc. All Rights Reserved.</li>
                    <!-- <li>Privacy Policy</li>
                    <li>Terms and Conditions</li> -->
                </ul>
            </div>
        </div>
    </section>
</footer>