<div class="flex-row tight">
    <div class="contact-icon">
        <i class="fas fa-child-reaching"></i>
    </div>
    <div class="contact-name">
        <div class="flex-column tighter">
            <h5>{{contact.firstName}} {{contact.lastName}}</h5>
            <span>Age {{ getAge(contact) }}</span>
        </div>
    </div>
</div>
