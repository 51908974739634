<main>
    <section class="mentoring">
        <div class="content">
            <div class="container">
                <div class="left">
                    <img class="full" src="../../assets/mentoring-1.png" />
                    <img class="mobile" src="../../assets/Mobile/mentoring-1.png" />
                </div>
                <div class="right">
                    <h2>
                        <div class="cursive">all about</div>
                        <div>Mentoring</div>
                    </h2>

                    <div class="video-container">
                        <iframe src="https://www.youtube.com/embed/-H5X5aBhZ0c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>

                    <h3>What to Expect</h3>
                    <p>Your Mentor Mom is an experienced, caring mom who wants to listen to your story and learn about your hopes and aspirations. Reach out to her anytime with a text or call. Whether you're feeling upset, scared, frustrated, or just want to get something off your chest, your mentor will be there for you. She knows how challenging it is being a parent and will do whatever it takes to help you.</p>
                    <section class="tagline">
                        <div class="content">
                            <div class="tagline-content">
                                <ul>
                                    <li><h4 class="text-light-blue">Be Seen</h4><p>We see you.  We know this isn't easy.  We love and respect you for boldly choosing to work toward being the best parent you can be.</p></li>
                                    <li><h4 class="text-pink">Be Heard</h4><p>We will take the time to listen to you.  Every journey is unique.  Your story matters.</p></li>
                                    <li><h4 class="text-dark-blue">Be Known</h4><p>We want to know you.  You aren't too "messy" for us.  Our mentor moms are not here to judge and lecture you. Many of them have been in your shoes.  They want to be the people they themselves needed at that time.  You have <b>purpose</b> and you are loved no matter what has happened in your past.</p></li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    
                    <p>We would love to hear from you.</p>

                    <section class="rsvp" #rsvp>
                    </section>
                </div>
            </div>
        </div>
    </section>
</main>
