<div class="p2-dialog">
    <header>
        <h1>Change Phone Number</h1>
        <button type="button" role="button" aria-label="Close" class="close" (click)="onCancel()">
            <i class="fa fa-xmark"></i>
        </button>
    </header>
    <div class="content">
        <phone-lookup [(contact)]="contact" [isPhoneChange]="true" (contactChange)="onContactChange($event)"></phone-lookup>
    </div>
    <footer>
        <div>   
            <button class="secondary" (click)="onCancel()">Cancel</button>
        </div>
    </footer>
</div>
