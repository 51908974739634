<main>
    <section class="classes">
        <div class="content">
            <h2>
                <div class="cursive">check out</div>
                <div>Free Upcoming Classes</div> 
            </h2>

            <ul class="classes">
                <li>
                    <img class="full" src="../../assets/healthy-parenting-icon.png" />
                    <img class="mobile" src="../../assets/healthy-parenting-icon.png" />

                    <div class="right">
                        <h4>Healthy Parenting</h4>
                        <p>Parenting can be exhausting.  Learn from experts in child development about intellectual development, communicating with your kids, the dynamics of discipline, instilling self-esteem, teaching children values, meeting their needs, being a good role model, and more!</p>
                        <button class="pink medium register" routerLink="/register">Register</button>
                    </div>
                </li>
                <li>
                    <img class="full" src="../../assets/stretch-a-dollar.png" />
                    <img class="mobile" src="../../assets/stretch-a-dollar.png" />
                    <div class="right">
                        <h4>How to Stretch a Dollar</h4>
                        <p>A dollar doesn't go as far as it used to.  Get practical tips on how to get the most out of every dollar -- smart shopping, eating cheap, affordable transporation, and more.</p>
                        <button class="pink medium register" routerLink="/register">Register</button>
                    </div>
                </li>
                <li>
                    <img class="full" src="../../assets/relationships-icon.png" />
                    <img class="mobile" src="../../assets/relationships-icon.png" />
                    <div class="right">
                        <h4>Making Smart Relationship Choices</h4>
                        <p>A relationship expert will guide you through the building blocks of positive relationships, how to assess relationships, realistic love, danger signs, and more.</p>
                        <button class="pink medium register" routerLink="/register">Register</button>
                    </div>
                </li>
                <li>
                    <img class="full" src="../../assets/cooking-icon.png" />
                    <img class="mobile" src="../../assets/cooking-icon.png" />
                    <div class="right">
                        <h4>Cooking & Nutrition</h4>
                        <p>Learn how to prepare affordable, healthy meals for you and your child.  Participants will learn what constitutes a healthy diet for children and adults and how to prepare simple, delicious foods aimed at inspiring a lifelong celebration of easy home-cooked meals.</p>
                        <button class="pink medium register" routerLink="/register">Register</button>
                    </div>
                </li>
                <li>
                    <img class="full" src="../../assets/career-icon.png" />
                    <img class="mobile" src="../../assets/career-icon.png" />
                    <div class="right">
                        <h4>Career Planning</h4>
                        <p>Topics include a comprehensive skills assessment, identification of work and personal values, career interests, work and learning styles, and personality types related to college majors and academic success.</p>
                        <button class="pink medium register" routerLink="/register">Register</button>
                    </div>
                </li>
                <li>
                    <img class="full" src="../../assets/wellness-icon.png" />
                    <img class="mobile" src="../../assets/wellness-icon.png" />
                    <div class="right">
                        <h4>Health & Wellness</h4>
                        <p>Learn about the role of health, nutrition, physical activity, and wellness in your daily life.  See how psychological and emotional health are connected to our overall wellbeing and health.</p>
                        <button class="pink medium register" routerLink="/register">Register</button>
                    </div>
                </li>
                <li>
                    <img class="full" src="../../assets/communication-icon.png" />
                    <img class="mobile" src="../../assets/communication-icon.png" />
                    <div class="right">
                        <h4>Communicating Effectively</h4>
                        <p>There's no way to insulate yourself from the dangers of miscommunication. Your ability to use the art of talk to effectively convey who you are and to build solid relationships not only influences the success of your friendships, romantic life, and everyday encounters, but also how you experience your workplace. Studies show that using conversational skills properly in that arena makes you more productive, happier, and less stressed.</p>
                        <button class="pink medium register" routerLink="/register">Register</button>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</main>
