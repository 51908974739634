<button type="button" role="button" aria-label="Login" class="login with-icon" *ngIf="!contact" routerLink="/login">
    <i class="fa fa-user"></i> <span class="login-label">Login</span>
</button>
<!-- <a class="text-with-icon" *ngIf="!contact" routerLink="/login"><i class="fa fa-user"></i>Login</a> -->

<ng-container *ngIf="!!contact">
    <button type="button" role="button" aria-label="Profile" class="profile" (click)="onProfileClick()">
        {{ getInitials() }}
    </button>
    <div class="profile-menu" *ngIf="isProfileMenuOpen" [@expandCollapse]>
        <div class="flex-row tighter user">
            <div class="circle gray">{{ getInitials() }}</div>
            <div class="flex-column tighter user-name">
                <span>{{contact.firstName}} {{contact.lastName}}</span>
                <span class="secondary">{{ contact.email }}</span>
            </div>
        </div>
        <ul (mouseup)="onMouseUp()">
            <li><a class="text-with-icon" routerLink="/user/profile"><i class="fa fa-user"></i> Profile</a></li>
            <li><a class="text-with-icon" routerLink="/user/children"><i class="fa fa-child"></i> Children</a></li>
            <li><a class="text-with-icon" routerLink="/user/events"><i class="fa fa-calendar"></i> Events</a></li>
            <li><a class="text-with-icon" routerLink="/user/preferences"><i class="fa fa-gear"></i> Preferences</a></li>
            <!-- <li><a class="text-with-icon"routerLink="/user/rewards"><i class="fa fa-award"></i> Rewards</a></li> -->
        </ul>
        <ul>
            <li><a class="text-with-icon" (click)="onLogout()"><i class="fa fa-arrow-right-from-bracket"></i> Logout</a></li>
        </ul>
    </div>
</ng-container>
