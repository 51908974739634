import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { Contact } from 'src/app/_models/contact';
import { expandCollapse } from '../animations/animations';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';

@Component({
    selector: 'user-profile-menu',
    templateUrl: './user-profile-menu.component.html',
    styleUrls: ['./user-profile-menu.component.scss'],
    animations: [expandCollapse]
})
export class UserProfileMenuComponent implements OnInit {
    @Input() contact: Contact;
    @Input() isProfileMenuOpen: boolean = false;

    @Output() isProfileMenuOpenChange = new EventEmitter<boolean>();
    
    constructor(private router: Router, private userService: UserService) { }

    ngOnInit(): void {
    }

    onCancel() {
    }

    getInitials() {
        return !!this.contact?.firstName && !!this.contact?.lastName ? this.contact.firstName.charAt(0) + this.contact.lastName.charAt(0) : '';
    }

    onProfileClick() {
        this.isProfileMenuOpen = true;
        
        this.isProfileMenuOpenChange.emit(this.isProfileMenuOpen);
    }

    onMouseUp() {
        this.isProfileMenuOpen = false;
        
        this.isProfileMenuOpenChange.emit(this.isProfileMenuOpen);
    }

    onLogout() {
        localStorage.removeItem('p2-token');
        this.userService.setUser(null);
        this.router.navigate(['/login']);
    }
}
