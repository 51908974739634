import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';
import { forkJoin, Subscription, take } from 'rxjs';
import { ContactPreferences } from 'src/app/_models/contact-preferences';
import { ContactService } from 'src/app/_services/contact.service';
import { UserService } from 'src/app/_services/user.service';
import { SelectButtonOption } from '../select-button/select-button.component';

@Component({
    templateUrl: './user-preferences.component.html',
    styleUrls: ['./user-preferences.scss']
})
export class UserPreferencesComponent implements OnInit {
    contactPreferences: ContactPreferences;
    userSubscription: Subscription;

    isLoading: boolean = false;

    yesNoOptions: SelectButtonOption[] = [
        { name: 'Yes', value: true },
        { name: 'No', value: false }
    ];

    constructor(private contactService: ContactService, private userService: UserService) {
        
    }

    ngOnInit() {
        this.userSubscription = this.userService.getUser().subscribe(contact => {
            if (!!contact) {   
                this.contactService.getContactPreferences(contact.guid).pipe(take(1)).subscribe(res => {
                    this.contactPreferences = res ?? { contactGuid: contact.guid } as ContactPreferences;
console.log(this.contactPreferences);                    
                });
            }
        })
    }

    ngOnDestroy() {
        if (!!this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    onCancel() {

    }

    onSave() {
        this.isLoading = true;

        this.contactService.updateContactPreferences(this.contactPreferences.contactGuid, this.contactPreferences).pipe(take(1)).subscribe(res => {
            this.userService.refreshUser();

            this.contactPreferences = res;

            this.isLoading = false;
        });
    }

    onPreferenceChange(isEnabled: boolean, field: string) {
console.log(isEnabled);        
        this.contactPreferences[field] = isEnabled ? null : new Date();
console.log(this.contactPreferences);        
    }

    getValue(date: Date): boolean {
        return !date;
    }
}
