import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  templateUrl: './mentoring.component.html',
  styleUrls: ['./mentoring.component.scss']
})
export class MentoringComponent implements OnInit {

  @ViewChild('rsvp') register;

  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
		var registerScript = document.createElement("script");
		registerScript.async = true;
		registerScript.setAttribute("src", "https://www.cognitoforms.com/f/seamless.js");
    registerScript.setAttribute("data-key", "2bfEHdCOdUeFWW4pLbCtWA");
    registerScript.setAttribute("data-form", "8");
    this.register.nativeElement.appendChild(registerScript);

  }
}
