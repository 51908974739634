import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ErrorService {
    api: string = environment.api;

    constructor(private httpClient: HttpClient) { }

    reportError(error: any): Observable<void> {
        var url = `${this.api}error/report`;

        return this.httpClient.post<void>(url, error);
    }
}
