import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staff-board',
  templateUrl: './staff-board.component.html',
  styleUrls: ['./staff-board.component.scss']
})
export class StaffBoardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
