<main class="p2-app">
    <section class="flex-column">
        <p *ngIf="!isTokenValid">Token is incorrect</p>

        <div class="content" *ngIf="!!event">
            <h2>Check In</h2>

            <div class="card">
                <event-display [event]="event"></event-display>
                <div class="flex-column tight" *ngIf="!!contactEvent.contact?.guid">
                    <contact-display [contact]="contactEvent.contact"></contact-display>
            
                    <div class="flex-row tight">
                        <a (click)="onChangeContact()" [attr.disabled]="isLoading">Logout</a> 
                        <a (click)="onEditContact()" *ngIf="currentStep != 'contact-basic-info'">Edit Details</a>
                    </div>
                </div>

                <phone-lookup *ngIf="currentStep == 'contact-selection'" [(contact)]="contactEvent.contact" (contactChange)="onNextClick()"></phone-lookup>

                <div class="flex-column tighter" *ngIf="currentStep == 'contact-basic-info'">
                    <h5>Parent Info</h5>
                    <br>

                    <contact-basic-info-form  [(contact)]="contactEvent.contact" [(isFormValid)]="isProfileFormValid" [lockPhone]="isNewContact()"></contact-basic-info-form>

                    <sms-consent *ngIf="isNewContact()"></sms-consent>
                    
                    <client-profile-form [(clientInfo)]="contactEvent.contact.clientInfo" [(isFormValid)]="isClientFormValid"></client-profile-form>

                    <contact-ethnicity-form [(selectedEthnicities)]="contactEvent.contact.ethnicities"></contact-ethnicity-form> 

                    <photo-release-form [(clientInfo)]="contactEvent.contact.clientInfo"></photo-release-form>
                </div>

                <ng-container *ngIf="currentStep == 'child-selection'">
                    <ng-container *ngIf="isClient()">
                        <child-selection *ngIf="!contactEvent.event.isParentsOnly" [enableSelection]="true" [parentGuid]="contactEvent.contact.guid" [(children)]="contactEvent.contact.children" title="Which children are attending?"></child-selection>
        
                        <div *ngIf="contactEvent.event.isParentsOnly">
                            <p><b>Note:</b>&nbsp;This event is parents only (no kids).</p>
                        </div>
                    </ng-container>
                </ng-container>
    
                <div>
                    <h4 *ngIf="currentStep == 'confirmation'" class="text-with-icon"><i class="fas fa-thumbs-up"></i> You're all set, {{ contactEvent.contact.firstName }}!</h4>

                    <button class="light-blue with-icon right medium"  (click)="onNextClick()" [loading]="isLoading" [disabled]="!isProfileFormValid || !isClientFormValid || !contactEvent.contact" *ngIf="currentStep != 'confirmation'">
                        {{currentStep == 'child-selection' ? 'Complete Check In' : 'Next' }}
                        <i class="fa fa-chevron-right" *ngIf="currentStep != 'child-selection'"></i>
                    </button>
                </div>
            </div>
        </div>
    </section>
</main>
