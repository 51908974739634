<main>
    <section class="with-curve">
        <div class="content">
            <div class="container">
                <h2>
                    <div class="cursive">the heart behind</div>
                    <div>P2</div>
                </h2>

                <div class="video-container">
                    <iframe src="https://www.youtube.com/embed/CO9Z954_Noc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>

                <div class="stats">
                    <button routerLink="/donate" class="donate pink">Donate Now</button>
                </div>

                <div class="stats">
                    <h3>Troubling Statistics</h3>
                    <ul>
                        <li>20% of teen moms have repeat teen pregnancies. (HHS.gov 2018)</li>
                        <li>Only 40% of teen moms will graduate from high school. (NCSL 2018)</li>
                        <li>Only 2% of teen moms go to college. (NCSL 2018)</li>
                        <li>Only 32% of teen moms get off public aid and become financially independent. (NCSL 2018)</li>
                        <li>Only 34% of teen moms break the cycle of generational teen parenting, poverty, and poor outcomes for their children. (NCSL 2018)</li>
                    </ul>
                </div>

                <div class="stats">
                    <h3>Pitfalls for Teen Moms</h3>
                    <ul>
                        <li>Giving up on education</li>
                        <li>Providing financially in unhealthy ways</li>
                        <li>Becoming codependent</li>
                        <li>Ostracized by friends and/or family</li>
                        <li>Seeking comfort in substances</li>
                        <li>No positive role models</li>
                    </ul>
                </div>

                <div class="stats">
                    <h3>Effects on the Children</h3>
                    <p>Statistically, children raised in poor, single-mother homes are:</p>
                    <ul>
                        
                        <li>5 times more likely to commit suicide</li>
                        <li>9 times more likely to dropout of high school</li>
                        <li>10 times more likely to abuse drugs</li>
                        <li>14 times more likely to commit rape</li>
                        <li>20 times more likely to end up in prison</li>
                        <li>32 times more likely to run away from home</li>
                    </ul>
                </div>
            </div>
            <curve-bottom></curve-bottom>
        </div>
    </section>

    <section>
        <div class="content">
            <div class="container">
                <h3>Core Objectives</h3>

                <h4>We seek to:</h4>
                <ul>
                    <li>Introduce individuals to the God who loves them unconditionally.  Or for believers, help them take the next step in their journey with Christ.</li>
                    <li>Build a parent's identity in Jesus Christ -- not in worldly perception, relationships, or status.</li>
                    <li>Guide single parents toward God-honoring relationship choices that will benefit them and their child.</li>
                    <li>Keep both parents involved in the child's life (when the situation is healthy).</li>
                    <li>Steer parents toward a completed education and/or job training to provide a steady income for their family.</li>
                    <li>Equip parents with life skills that will help them care for their child and stand on their own two feet.</li>
                    <li>Direct parents to a Bible-teaching church that will love and shepherd their families.</li>
                    <li>Partner with other community organizations to provide the help struggling families need.</li>
                </ul>
            </div>
        </div>
    </section>

    <section class="with-curve">
        <div class="content">
            <curve-top></curve-top>
            <div class="container">
                <h3>How Do We Get There?</h3>

                <ul>
                    <li>Mentoring. We pair each young moms with a caring Mentor Mom.</li>
                    <li>Mom Life groups. Doing life with other young moms.</li>
                    <li>Mommy & Me classes. Young moms learn about parenting and child development through fun activities with their kids.</li>
                    <li>Equip classes. An expert instructor teaches vital life skills including topics like Healthy Parenting, Cooking & Nutrition, Budgeting, How to Stretch a Dollar, Career Planning, Health & Wellness, Making Smart Relationship Choices and more.</li>
                </ul>

                <div class="stats">
                    <button routerLink="/donate" class="donate pink">Donate Now</button>
                </div>
            </div>
            <curve-bottom></curve-bottom>
        </div>
    </section>
</main>
