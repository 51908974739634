import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactService } from '../_services/contact.service';

@Component({
	templateUrl: './unsubscribe.component.html',
	styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent  {

	isUnsubscribed: boolean = false;

	constructor(private route: ActivatedRoute, private contactService: ContactService) { 

	}

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
            if (!!params.get('id')) {
            	let contactGuid  = params.get('id');

				// Call the API to unsubscribe the contact
				this.contactService.unsubscribeContact(contactGuid).subscribe(res => {
					this.isUnsubscribed = true;
				})
			}
		})
	}
}
