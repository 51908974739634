<main class="p2-app" *ngIf="!!contact">
    <section class="profile">
        <div class="content">
            <h2>
                <div>Children</div> 
            </h2>

            <div class="flex-column">
                <child-selection [parentGuid]="contact.guid" [(children)]="contact.children"></child-selection>
            </div>
        </div>
    </section>
</main>
