<div class="p2-dialog">
    <header>
        <h1>{{ action == 'add' ? 'Add' : 'Edit' }} Child</h1>
        <button type="button" role="button" aria-label="Close" class="close" (click)="onCancel()">
            <i class="fa fa-xmark"></i>
        </button>
    </header>
    <div class="content">
        <contact-basic-info-form [(contact)]="child" [hideEmailPhone]="true" [(isFormValid)]="isFormValid"></contact-basic-info-form>
    </div>
    <footer>
        <div>
            <button class="error" (click)="onRemoveChild()" *ngIf="action == 'edit'">Remove</button>
        </div>
        
        <div>   
            <button class="secondary" (click)="onCancel()">Cancel</button>
            <button class="pink" (click)="onSave()" [loading]="isLoading" [disabled]="!isFormValid">Save</button>
        </div>
    </footer>
</div>
