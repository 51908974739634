<main>
    <section class="donate">
        <div class="content">
            <div class="left">
                <div class="left-content">
                    <h2>
                        <div class="cursive">make</div>
                        <div>A Donat<span class="i-with-dot">i</span>on</div> 
                    </h2>

                    <div class="iframe-placeholder">
                        <script src="https://donorbox.org/widget.js" paypalExpress="false"></script><iframe allowpaymentrequest="" frameborder="0" height="900px" name="donorbox" scrolling="no" seamless="seamless" src="https://donorbox.org/embed/parenting-with-purpose-inc" style="max-width: 500px; min-width: 250px; max-height:none!important" width="100%"></iframe>
                    </div>
                </div>
            </div>
            <div class="right">
                <img class="mobile" src="../../assets/Mobile/Donation-background.png" />
                <img class="tablet" src="../../assets/Tablet/Donation-background.png" />
            </div>
        </div>
    </section>
</main>
