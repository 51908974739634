<main class="p2-app" *ngIf="!!contactPreferences">
    <section class="profile">
        <div class="content">
            <h2>
                <div>Preferences</div> 
            </h2>

            <form #myForm="ngForm">
                <div class="field">
                    <label for="reminder-email" class="font-semibold w-6rem">Email event reminders</label>
                    <select-button id="reminder-email" name="reminder-email" [options]="yesNoOptions" 
                        [ngModel]="getValue(contactPreferences.dateEventReminderEmailDeclined)"
                        (ngModelChange)="onPreferenceChange($event, 'dateEventReminderEmailDeclined')"></select-button>
                </div>
                <div class="field">
                    <label for="reminder-sms" class="font-semibold w-6rem">Text event reminders</label>
                    <select-button id="reminder-sms" name="reminder-sms" [options]="yesNoOptions" 
                        [ngModel]="getValue(contactPreferences.dateEventReminderSmsDeclined)"
                        (ngModelChange)="onPreferenceChange($event, 'dateEventReminderSmsDeclined')"></select-button>
                </div>

                <div class="flex-row tight buttons">
                    <button class="pink medium" (click)="onSave()" [loading]="isLoading">Save</button>
                </div>
            </form>
        </div>
    </section>
</main>
