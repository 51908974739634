import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';
import { Subscription, take } from 'rxjs';
import { Contact } from 'src/app/_models/contact';
import { ContactService } from 'src/app/_services/contact.service';
import { JWTTokenService } from 'src/app/_services/jwt-token.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
    templateUrl: './children.component.html',
    styleUrls: ['./children.scss']
})
export class ChildrenComponent implements OnInit {
    contact: Contact;
    userSubscription: Subscription;

    constructor(private contactService: ContactService, private jwtTokenService: JWTTokenService, private userService: UserService) {
        this.userSubscription = this.userService.getUser().subscribe(user => {
            this.contact = user;
        })
    }

    ngOnInit() {

    }

    ngOnDestroy() {
        if (!!this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    onCancel() {

    }

    onSave() {
        this.contactService.updateContact(this.contact).pipe(take(1)).subscribe(res => {
            this.userService.setUser(res);
        });
    }
}
