<div class="flex-row tight">
    <div class="contact-icon" *ngIf="contact.gender?.length > 0">
        <img *ngIf="contact.gender == 'M'" src="../../../assets/male-icon.png">
        <img *ngIf="contact.gender == 'F'" src="../../../assets/female-icon.png">
    </div>
    <div class="contact-name">
        <div class="flex-column tight">
            <h5>{{contact.firstName}} {{contact.lastName}}</h5>
            <div class="flex-row tight responsive">
                <p class="text-with-icon" *ngIf="contact.phoneNumber?.length > 0"><i class="fa fa-phone"></i>{{ contact.phoneNumber | phone }}</p>
                <p class="text-with-icon" *ngIf="contact.email?.length > 0"><i class="far fa-envelope"></i>{{ contact.email }}</p>
            </div>
        </div>
    </div>
</div>
