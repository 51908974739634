<div class="p2-dialog">
    <header>
        <h1>{{ title }}</h1>
        <button type="button" role="button" aria-label="Close" class="close" (click)="onCancel()">
            <i class="fa fa-xmark"></i>
        </button>
    </header>
    <div class="content">
        {{ message }}
    </div>
    <footer>
        <button class="secondary" (click)="onCancel()">{{ buttonText }}</button>
    </footer>
</div>
