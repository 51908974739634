<div class="flex flex-column">
    <div class="flex-row start responsive">
        <div class="event-icon">
            <img #thumbnail [src]="getImageUrl(event, 'thumbnail')" />
        </div>
        <div class="event-name">
            <div class="flex-column tight">
                <h5>{{event.name}}</h5>

                <div class="flex-row tight space-between responsive">
                    <span class="text-with-icon">
                        <img src="../../../assets/calendar-icon.png">
                        {{ event.startTime | date: 'MMMM d, yyyy'}}
                    </span>
                    <span class="text-with-icon">
                        <img src="../../../assets/wrist-watch-icon.png">
                        <span>{{ event.startTime | date: 'h:mm aa'}}</span>
                        <span *ngIf="!!event.endTime">- {{ event.endTime | date: 'h:mm aa'}}</span>
                    </span>
                </div>
                <div>
                    <span class="text-with-icon">
                        <img src="../../../assets/location-pin-icon.png">
                        {{ event.eventLocation.name }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
