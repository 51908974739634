import { trigger, state, style, animate, transition, group } from '@angular/animations';

export const expandCollapse =  
  trigger('expandCollapse', [
      state('in', style({height: '*', opacity: 0})),
      transition(':enter', [
          style({height: '0', opacity: 0}),

          group([
              animate(120, style({height: '*'})),
              animate('240ms ease-in-out', style({'opacity': '1'}))
          ])

      ]),
      transition(':leave', [
          style({height: '*', opacity: 1}),

          group([
              animate(240, style({height: 0})),
              animate('120ms ease-in-out', style({'opacity': '0'}))
          ])

      ])
  ]);