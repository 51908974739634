<form #myForm="ngForm">
    <div class="flex-row tight responsive">
        <div class="field flex-1">
            <label for="first-name" class="font-semibold required-field">First name</label>
            <div class="input-container">
                <input type="text" id="first-name" name="first-name" [(ngModel)]="contact.firstName"
                required #firstName="ngModel" maxlength="128" />
                
                <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                    <small class="error" *ngIf="firstName.errors?.['required']">First name is required</small>
                </div>
            </div>
        </div>
        <div class="field flex-1">
            <label for="last-name" class="font-semibold required-field">Last name</label>
            <div class="input-container">
                <input type="text" id="last-name" name="last-name" [(ngModel)]="contact.lastName"
                required #lastName="ngModel" maxlength="128" />
                
                <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                    <small class="error" *ngIf="lastName.errors?.['required']">Last name is required</small>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-row tight responsive" *ngIf="!hideEmailPhone">
        <div class="field flex-1">
            <label for="email" class="font-semibold required-field">Email</label>
            <div class="input-container">
                <input type="email" id="email" name="email" 
                [(ngModel)]="contact.email" #email="ngModel" maxlength="256" [email]="true" required />
    
                <div *ngIf="email.invalid && (email.dirty || email.touched)">
                    <small class="error" *ngIf="email.errors?.['email']">Invalid email</small>
                    <small class="error" *ngIf="email.errors?.['required']">Email is required</small>
                </div>
            </div>
        </div>
        <div class="field flex-1" *ngIf="!hideEmailPhone">
            <label for="phoneNumber" class="font-semibold required-field">Phone number</label>
            <div class="input-container flex-row tight">
                <p id="phoneNumber" name="phoneNumber">{{ contact.phoneNumber | phone }}</p>
                <a *ngIf="!lockPhone" (click)="onChangePhoneNumber()">Change</a> 
            </div>
        </div>
    </div>
    <div class="flex-row tight responsive">
        <div class="field">
            <label for="dateOfBirth" class="font-semibold required-field">Date of Birth</label>
            <div class="input-container">
                <input [ngModel]="contact.dateOfBirth | date:'yyyy-MM-dd'" (ngModelChange)="contact.dateOfBirth = $event" type="date" id="dateOfBirth" name="dateOfBirth" #dateOfBirth="ngModel" required />

                <div *ngIf="dateOfBirth.invalid && (dateOfBirth.dirty || dateOfBirth.touched)">
                    <small class="error" *ngIf="dateOfBirth.errors?.['required']">Date of birth is required</small>
                </div>
            </div>

            <!-- <date-input-masked id="dateOfBirth" name="dateOfBirth" ngDefaultControl [showCalendar]="false" [(ngModel)]="contact.dateOfBirth"></date-input-masked> -->
        </div>
        <div class="field">
            <label for="gender" class="font-semibold">Gender</label>
            <select-button id="gender" name="gender" [options]="allGenders" [(ngModel)]="contact.gender"></select-button>
        </div>
    </div>
</form>
