import { Component, OnInit, Input, EventEmitter, Output, Inject } from '@angular/core';
import * as _ from 'lodash';
import { Contact } from 'src/app/_models/contact';
import * as dayjs from 'dayjs';
import { take } from 'rxjs';
import { DialogRef, DIALOG_DATA, Dialog } from '@angular/cdk/dialog';
import { ContactEvent } from 'src/app/_models/contact-event';
import { DialogData } from '../event-calendar/event-calendar.component';
import { ContactService } from 'src/app/_services/contact.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

export interface ChangePhoneDialogData {
    contact: Contact;
}

@Component({
    selector: 'change-phone-dialog',
    templateUrl: './change-phone-dialog.component.html',
    styleUrls: ['./change-phone-dialog.scss']
})
export class ChangePhoneDialogComponent implements OnInit {
    contact: Contact;

    isLoading: boolean = false;
    
    constructor(public dialogRef: DialogRef<ChangePhoneDialogData>, @Inject(DIALOG_DATA) public data: ChangePhoneDialogData, public dialog: Dialog) {

    }

    ngOnInit() {
        this.contact = this.data.contact;
    }

    onContactChange(contact: Contact) {
        if (this.contact) {
            this.dialogRef.close({ contact: this.contact});
        }
    }

    onCancel() {
        this.dialogRef.close();
    }
}
