<main>
    <section class="top">
        <img class="full" src="../../assets/Banner.png" />

        <div class="content">
            <div class="left">
                <div class="left-content">
                    <h1>
                        <div>Come to our next</div>
                        <div><span class="light_blue_for_dark_background large">Mommy & Me</span></div>
                        <div class="cursive-line"><span><span class="cursive">Where</span> Moms & Kids</span></div>
                        <div><span class="white large">Play, Connect</span></div>
                        <div><span class="white large">and Grow</span></div>
                    </h1>
                    <button class="pink medium register" routerLink="/events">Upcoming Events</button>
                </div>
            </div>
            <img class="mobile" src="../../assets/Mobile-Banner.png" />
        </div>
    </section>

    <section class="keeping-up">
        <curve-top></curve-top>

        <div class="container">
            <div class="content">
                <div class="left"> 
                    <div class="video-container">
                        <iframe src="https://www.youtube.com/embed/JPciyhFtOGI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
                <div class="right">
                    <h3>Keeping up with a little one is <span class="cursive">exhausting</span> and leaves moms <span class="cursive">isolated</span></h3>
                    <p>Mommy & Me events offer a fun environment for you and your child to play and learn together. Get to know other moms while your child enjoys playing with other kids their age.</p>
                </div>
            </div>
        </div>
    </section>

    <section class="mentoring" id="mentoring">
        <div class="content">
            <div class="left">
                <img class="mobile" src="../../assets/mentoring-1.png">
            </div>

            <div class="right">
                <h3>Mentor <span class="cursive">Moms</span></h3>
                <p>Let us introduce you to a Mentor Mom. She is an experienced parent who knows about the challenges you're facing and wants to be there for you every step of the way.</p>
                <a routerLink="/mentoring">Learn more <i class="material-icons">arrow_right_alt</i></a>
            </div>
        </div>
    </section>

    <section class="mom-life" id="mom-life">
        <div class="content">
            <h3><span class="cursive">Mom Life</span> Groups</h3>
            <p>Small groups (4 to 8 moms) get together regularly to talk about the highs and lows of mom life, learn from each other, and do life together.</p>
            <a routerLink="/register">Register <i class="material-icons">arrow_right_alt</i></a>
        </div>
    </section>

    <section class="equip-classes" id="equip-classes">
        <div class="content">
            <div class="left">
                <h3>Equip <span class="cursive">Classes</span></h3>
                <p>Learn about child development, living on a budget, cooking, relationships, career planning and more.</p>
                <a routerLink="/life-skills-classes">Learn more <i class="material-icons">arrow_right_alt</i></a>
            </div>
            <div class="right">
                <img class="mobile" src="../../assets/equip-classes.png">
            </div>
        </div>
    </section>

    <section class="who-we-serve" id="who-we-serve">
        <div class="content">
            <h4 class="mobile">All Services are<br> <span class="text-light-blue">FREE</span><br>to Our Clients</h4>
            <h2 class="full">All Services are <span class="text-light-blue">FREE</span> to Our Clients</h2>
        </div>
    </section>

    <section class="tagline-bottom">
        <div class="content">
            <curve-top bgColor="#10102B"></curve-top> 
            <div class="tagline-bottom-content">
                <div class="tagline-content">
                    <span class="text-light-blue-for-dark-background">Be Seen</span>
                    <span class="dot">&bull;</span>
                    <span class="text-pink">Be Heard</span>
                    <span class="dot">&bull;</span>
                    <span class="text-dark-blue-for-dark-background">Be Known</span>
                </div>
                <button class="pink medium register" routerLink="/register">Register Today</button>
            </div>
            <curve-bottom bgColor="#10102B"></curve-bottom> 
        </div>
    </section>
</main>
