<main class="p2-app">
    <section class="profile">
        <div class="content">
            <h2>
                <div>Register</div> 
            </h2>

            <div class="flex-column tight">
                <phone-lookup *ngIf="currentStep == 'contact-selection'" [(contact)]="contact" (contactChange)="onContactChanged($event)"></phone-lookup>
                
                <div class="card" *ngIf="currentStep == 'contact-basic-info'">
                    <h5>Parent Info</h5>

                    <div class="flex-column tighter">
                        <contact-basic-info-form *ngIf="currentStep == 'contact-basic-info'" [(contact)]="contact" [(isFormValid)]="isProfileFormValid" [lockPhone]="true"></contact-basic-info-form>

                        <client-profile-form *ngIf="currentStep == 'contact-basic-info'" [(clientInfo)]="clientInfo" [(isFormValid)]="isClientFormValid"></client-profile-form>

                        <contact-ethnicity-form *ngIf="currentStep == 'contact-basic-info'" [(selectedEthnicities)]="ethnicities"></contact-ethnicity-form>

                        <photo-release-form [(clientInfo)]="clientInfo"></photo-release-form>
                    </div>
                </div>

                <div class="card children" *ngIf="currentStep == 'child-selection'">
                    <child-selection [enableSelection]="false" [parentGuid]="contact.guid" [(children)]="contact.children" title="Children"></child-selection>
                </div>

                <button class="light-blue with-icon right" *ngIf="currentStep != 'contact-selection'"  (click)="onNextClick()" [loading]="isLoading" [disabled]="!isProfileFormValid || !isClientFormValid">
                    {{currentStep == 'child-selection' ? 'Finish' : 'Next' }}
                    <i class="fa fa-chevron-right" *ngIf="currentStep != 'child-selection'"></i>
                </button>
            </div>
        </div>
    </section>
</main>
