import { Input, Directive, ElementRef, OnChanges } from '@angular/core';
import * as _ from 'lodash';

@Directive({
    selector: '[loading]'
})
  export class LoadingDirective implements OnChanges {
    @Input() loading: boolean = false;

    constructor(private el: ElementRef) {
    }

    ngOnChanges() {
        if (this.loading && this.el.nativeElement.classList.contains('lds-dual-ring') == false) {
            this.el.nativeElement.disabled = true;
            this.el.nativeElement.classList.add('lds-dual-ring');
        }
        else if (!this.loading && this.el.nativeElement.classList.contains('lds-dual-ring')) {
            this.el.nativeElement.disabled = false;
            this.el.nativeElement.classList.remove('lds-dual-ring');
        }
    }
}
