import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'curve-bottom',
  templateUrl: './curve-bottom.component.html',
  styleUrls: ['./curve-bottom.component.scss']
})
export class CurveBottomComponent implements OnInit {
  @Input() bgColor: string = '#F1F7FA';

  constructor() { }

  ngOnInit(): void {
  }

}
