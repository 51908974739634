import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, take, tap } from 'rxjs';
import { Contact } from 'src/app/_models/contact';
import { environment } from 'src/environments/environment';
import { ClientInfo } from '../_models/client-info';
import { ContactEvent } from '../_models/contact-event';
import { LoginResult } from '../_models/login-result';
import * as dayjs from 'dayjs';
import * as _ from 'lodash';
import { JWTTokenService } from './jwt-token.service';
import { ContactPreferences } from '../_models/contact-preferences';

@Injectable()
export class ContactService {
    api: string = environment.api;

    constructor(private httpClient: HttpClient, private jwtTokenService: JWTTokenService) { }

    getContact(id: string): Observable<Contact> {
        var url = `${this.api}contacts/${id}`;

        return this.httpClient.get<Contact>(url);
    }

    formatDob(child: Contact) {
        if (!!child.dateOfBirth && dayjs(child.dateOfBirth).isValid) {
            child.dateOfBirth = dayjs(child.dateOfBirth).toDate();
        }
    }

    addChild(parentId: string, child: Contact): Observable<Contact> {
        var url = `${this.api}contacts/${parentId}/child`;

        this.formatDob(child);

        return this.httpClient.post<Contact>(url, child);
    }

    updateChild(parentId: string, child: Contact): Observable<Contact> {
        var url = `${this.api}contacts/${parentId}/child`;

        this.formatDob(child);

        return this.httpClient.put<Contact>(url, child);
    }

    addContact(contact: Contact): Observable<Contact> {
        var url = `${this.api}contacts`;

        this.formatDob(contact);

        return this.httpClient.post<Contact>(url, contact, { 
            observe: 'response'
        }).pipe(take(1), map(res => {
            if (res.headers.has('p2token')) {
                localStorage.setItem('p2-token', res.headers.get('p2token'));
            }

            return res.body;
        }));
    }

    updateContact(contact: Contact): Observable<Contact> {
        var url = `${this.api}contacts`;

        this.formatDob(contact);

        return this.httpClient.put<Contact>(url, contact);
    }

    removeChildFromParent(parentId: string, childId: string): Observable<void> {
        var url = `${this.api}contacts/${parentId}/child/${childId}`;

        return this.httpClient.delete<void>(url);
    }

    updateClientInfo(clientInfo: ClientInfo): Observable<ClientInfo> {
        var url = `${this.api}contacts/${clientInfo.contactGuid}/clientInfo`;

        return this.httpClient.put<ClientInfo>(url, clientInfo);
    }

    authenticateWithPhoneNumber(phoneNumber: string): Observable<void> {
        var url = `${this.api}contacts/token/phone/${phoneNumber}`;

        return this.httpClient.post<void>(url, null);
    }

    validateCode(phoneNumber: string, verificationCode: string): Observable<LoginResult> {
        var url = `${this.api}contacts/token/phone/${phoneNumber}/code/${verificationCode}`;

        return this.httpClient.post<LoginResult>(url, null);
    }

    changePhoneNumber(contactId: string, phoneNumber: string, verificationCode: string): Observable<LoginResult> {
        var url = `${this.api}contacts/${contactId}/token/phone/${phoneNumber}/code/${verificationCode}`;

        return this.httpClient.put<LoginResult>(url, null);
    }

    getEventsForContact(contactGuid: string): Observable<ContactEvent[]> {
        var url = `${this.api}contacts/${contactGuid}/events`;

        return this.httpClient.get<ContactEvent[]>(url).pipe(take(1), map(res => {
            return _.filter(res, c => c.status != 'Canceled');
        }));
    }

    getCurrentUserGuid(): string {
        return this.jwtTokenService.getContactGuid();
    }

    getCurrentUser(): Observable<Contact> {
        let contactGuid = this.jwtTokenService.getContactGuid();

        if (contactGuid?.length > 0) {
            var url = `${this.api}contacts/${contactGuid}`;

            return this.httpClient.get<Contact>(url);
        }
        else {
            return of(null);
        }
    }

    updateContactEthnicities(contactGuid: string, ethnicityIds: string[]): Observable<void> {
        var url = `${this.api}contacts/${contactGuid}/ethnicity`;

        return this.httpClient.put<void>(url, ethnicityIds);
    }

    getContactPreferences(contactGuid: string): Observable<ContactPreferences> {
        var url = `${this.api}contacts/${contactGuid}/preferences`;

        return this.httpClient.get<ContactPreferences>(url);
    }

    updateContactPreferences(contactGuid: string, preferences: ContactPreferences): Observable<ContactPreferences> {
        var url = `${this.api}contacts/${contactGuid}/preferences`;

        return this.httpClient.put<ContactPreferences>(url, preferences);
    }

    unsubscribeContact(contactGuid: string): Observable<void> {
        var url = `${this.api}contacts/${contactGuid}/unsubscribe`;

        return this.httpClient.post<void>(url, null);
    }

    isBasicInfoComplete(contact: Contact): boolean {
        return contact?.firstName?.length > 0 &&
            contact?.lastName?.length > 0 &&
            contact?.phoneNumber?.length > 0 &&
            contact?.email?.length > 0 &&
            !!contact?.dateOfBirth;
    }
}
