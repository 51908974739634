import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { Contact } from 'src/app/_models/contact';
import * as dayjs from 'dayjs';

@Component({
    selector: 'child-display',
    templateUrl: './child-display.component.html',
    styleUrls: ['./child-display.scss']
})
export class ChildDisplayComponent implements OnInit {
    @Input() contact: Contact;

    constructor() {
        
    }

    ngOnInit() {
    }

    getAge(child: Contact) {
        return !!child.dateOfBirth ? dayjs().diff(dayjs(child.dateOfBirth), 'year') : 'Unknown';
    }
}
