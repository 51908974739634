<main>
    <section class="mentoring">
        <div class="content">
            <div class="container">
                <div class="left">
                    <swiper-container slides-per-view="1" speed="500" loop="true" css-mode="true" navigation="true" pagination="true" scrollbar="true" [autoplay]="{ delay: 5000}">
                        <swiper-slide>
                            <img src="../../assets/mam-3.png" />
                        </swiper-slide>
                        <swiper-slide>
                            <img src="../../assets/mam-4.png" />
                        </swiper-slide>
                        <swiper-slide>
                            <img src="../../assets/mam-1.png" />
                        </swiper-slide>
                        <swiper-slide>
                            <img src="../../assets/mam-2.png" />
                        </swiper-slide>
                        <swiper-slide>
                            <img src="../../assets/mam-5.png" />
                        </swiper-slide>
                    </swiper-container>
                </div>
                <div class="right">
                    <h2>
                        <div class="cursive">all about</div>
                        <div>Mommy & Me</div>
                    </h2>
                    
                    <div class="video-container">
                        <iframe src="https://www.youtube.com/embed/JPciyhFtOGI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>

                    <div class="video-container">
                        <iframe src="https://www.youtube.com/embed/_6GMWPAXkZY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>

                    <h3>What to Expect</h3>
                    <p>
                        It can be lonely and stressful being a young parent.  Mommy & Me nights offer a fun environment for you and your child to play and learn together. Get to know other moms while your child enjoys playing with other kids their age.
                    </p>

                    <p>We would love for you to come.</p>
                    <button class="pink medium register" routerLink="/events">Join Us</button>
                </div>
            </div>
        </div>
    </section>
</main>
