<form #myForm="ngForm">
    <div class="flex-row tight responsive">
        <div class="field full-width">
            <label for="address1" class="font-semibold w-6rem">
                Address 1
            </label>
            <div>
                <input type="text" id="address1" name="address1" [(ngModel)]="clientInfo.address1"
                 #address1="ngModel" maxlength="128" />
            </div>
        </div>
    </div>
    <div class="flex-row tight responsive">
        <div class="field full-width">
            <label for="address2" class="font-semibold w-6rem">
                Address 2
            </label>
            <div>
                <input type="text" id="address2" name="address2" [(ngModel)]="clientInfo.address2"
                 #address2="ngModel" maxlength="128" />
            </div>
        </div>
    </div>
    <div class="flex-row tighter">
        <div class="field city">
            <label for="city" class="font-semibold required-field">
                City
            </label>
            <div>
                <input type="text" id="city" name="city" [(ngModel)]="clientInfo.city"
                #city="ngModel" maxlength="64" required />

                <div *ngIf="city.invalid && (city.dirty || city.touched)">
                    <small class="error" *ngIf="city.errors?.['required']">City is required</small>
                </div>
            </div>
        </div>
        <div class="field state">
            <label for="state" class="font-semibold">
                State
            </label>
            <div>
                <input type="text" id="state" name="state" [(ngModel)]="clientInfo.state"
                #state="ngModel" maxlength="2" />
            </div>
        </div>
        <div class="field postalCode">
            <label for="postalCode" class="font-semibold">
                Zip
            </label>
            <div>
                <input type="text" id="postalCode" name="postalCode" [(ngModel)]="clientInfo.postalCode"
                #postalCode="ngModel" maxlength="10" />
            </div>
        </div>
    </div>
    <div class="flex-row tight responsive">
        <div class="field">
            <label for="preferred-name" class="font-semibold w-6rem">
                Preferred name
            </label>
            <div>
                <input type="text" id="preferred-name" name="preferred-name" [(ngModel)]="clientInfo.preferredName"
                 #preferredName="ngModel" maxlength="128" placeholder="Optional" />
            </div>
        </div>
        <div class="field">
            <label for="preferred-language" class="font-semibold w-6rem">Preferred language</label>
            <select-button id="preferred-language" name="preferred-language" [options]="allLanguages" [(ngModel)]="clientInfo.preferredLanguage"></select-button>
        </div>
    </div>
    <div class="flex-row tight responsive">
        <div class="field full-width">
            <label for="source" class="font-semibold w-6rem required-field">
                How did you hear about us?
            </label>
            <div>
                <input type="text" id="source" name="source" [(ngModel)]="clientInfo.source"
                 #source="ngModel" maxlength="128" required />

                 <div *ngIf="source.invalid && (source.dirty || source.touched)">
                    <small class="error" *ngIf="source.errors?.['required']">Response is required</small>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-row tight responsive">
        <div class="field full-width">
            <label for="choicesLocation" class="font-semibold w-6rem">Are you a Choice LRC client?</label>
            <select id="choicesLocation" name="choicesLocation" [(ngModel)]="clientInfo.choicesLocation" (ngModelChange)="clientInfo.choicesLocation = clientInfo.choicesLocation || null">
                <option [ngValue]="undefined">No</option>
                <option value="Choices LRC New Albany">Yes, Choices LRC New Albany</option>
                <option value="Choices LRC Corydon">Yes, Choices LRC Corydon</option>
            </select>
        </div>
    </div>
</form>
